import type { Component } from "svelte"
import { get, readable, type Readable } from "svelte/store"
import { sortBy } from 'sort-by-typescript';

type BlogPostFrontMatter = {
  title: string
  description: string
  created: Date
  updated: Date
}

type RawBlogPost = {
  default: Component
  metadata: BlogPostFrontMatter
}

const raw_pages = import.meta.glob<RawBlogPost>("./blogposts/*.svx", { eager: true })

function basename(path: string) {
  //These can be hardcoded, because the path we glob-import from is hardcoded
  return path.substring("./blogposts/".length, path.length - 4)
}

export type BlogPost = BlogPostFrontMatter & {
  slug: string
  component: Component
}

const unsorted_pages: BlogPost[] = Object.keys(raw_pages).map((key) => {
  const page = raw_pages[key]

  const metadata = {
    ...page.metadata,
    created: new Date(page.metadata.created),
    updated: new Date(page.metadata.updated),
  }
  return {
    slug: basename(key),
    ...metadata,
    component: page.default,
  }
})

const sorted_posts_readable: Readable<BlogPost[]> = readable<BlogPost[]>(
  unsorted_pages.sort(sortBy('created'))
)

export const sorted_posts: BlogPost[] = get(sorted_posts_readable)
